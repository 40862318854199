import { useContext } from 'react';
import { ConfigContext, Config } from '../contexts/backendConfig';

function getConfigUrl(origin = window.location.origin) {
  const urlParse = new URL(origin);
  if (origin.includes('virtuallucyweb.co.uk')) {
    let apiurl;
    apiurl = origin.replace(/(dev|ndev|james|uat|nuat|pre|tst)[^.]*/gi, '$1api');
    apiurl = apiurl.replace(/(app|hbstest)/gi, 'prodapi');
    apiurl = apiurl.replace(/(patient|dcg|sth|ncic|barts|nhbstest)/gi, 'nprodapi');
    const configUrl = `${apiurl}/v1/triage-settings/${urlParse.host}`;
    console.log(`Config url(taken from origin): ${configUrl}`);
    return configUrl;
  }

  // @ts-ignore
  console.log(`Config url(taken from environment): ${process.env.REACT_APP_SETTINGS_URL}`);
  // @ts-ignore
  return process.env.REACT_APP_SETTINGS_URL;
}

const getDynamicConfig = async () => {
  try {
    //const response = await fetch(getConfigUrl());
    // @ts-ignore
    const response = await fetch(process.env.REACT_APP_SETTINGS_URL);
    const config = await response.json();
    const formattedConfig = makeKeysUpperCase(config);

    return { config: formattedConfig, response };
  } catch (error) {
    throw new Error(error);
  }
};

function makeKeysUpperCase(config) {
  return Object.fromEntries(Object.entries(config).map(([key, value]) => [key.toUpperCase(), value]));
}

export { getDynamicConfig, makeKeysUpperCase };
