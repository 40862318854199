import ServiceApi from '../services/service.api';

const baseServiceUrl = `/clinicians`;
const ApiClinicians = {
  getAll: (params: Record<string, unknown>) => {
    const url = `${baseServiceUrl}/clinicians/${(ServiceApi as Record<string, unknown>).userGuid}`;
    return new Promise((resolve, reject) => {
      ServiceApi.get(url, { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  getReferralClinicians: (params: Record<string, unknown>) => {
    const url = `${baseServiceUrl}/refclins/${(ServiceApi as Record<string, unknown>).userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getRecordingAvailability: ({ case_id }: { case_id: number }) => {
    const url = `${baseServiceUrl}/appt/calls/${(ServiceApi as Record<string, unknown>).userGuid}`;
    return ServiceApi.get(url, { params: { case_id } });
  },
  requestCallRecording: ({
    call_id,
    recording_state,
    appt_id
  }: {
    call_id: number;
    recording_state?: 'COMPSTART' | 'COMPEND' | 'COMPERROR';
    appt_id: number;
  }) => {
    const url = `${baseServiceUrl}/appt/calls/state/${(ServiceApi as Record<string, unknown>).userGuid}`;
    return ServiceApi.put(url, { call_id, recording_state, appt_id });
  },
  getInvreq: (params: Record<string, unknown>) => {
    const url = `${baseServiceUrl}/invreq/${(ServiceApi as Record<string, unknown>).userGuid}`;
    return ServiceApi.get(url, { params });
  },
  updateInvestigationProvider: (appt_id: unknown, hospital_id: unknown, img_type_code: unknown, ireq_id: unknown) => {
    return ServiceApi.post(`${baseServiceUrl}/invreq/provider`, {
      appt_id,
      hospital_id,
      img_type_code,
      ireq_id,
      user_guid: (ServiceApi as Record<string, unknown>).userGuid
    });
  },
  getApptPifuData: (appt_id: unknown) => {
    return ServiceApi.get(
      `${baseServiceUrl}/appt/pifu/${(ServiceApi as Record<string, unknown>).userGuid}/?appt_id=${appt_id}`
    );
  },
  saveApptPifuData: (params: Record<string, unknown>) => {
    const url = `${baseServiceUrl}/appt/pifu`;
    return ServiceApi.post(url, { ...params, user_guid: (ServiceApi as Record<string, unknown>).userGuid });
  }
};

export default ApiClinicians;
