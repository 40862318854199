import { Auth } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { TiWarningOutline } from 'react-icons/ti';
import { Link, withRouter } from 'react-router-dom';
import {
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  UncontrolledAlert,
  Button
} from 'reactstrap';
import { Api as LocalApi } from 'vl-common/src/api/index';
import ConfigLink from '../../../core/configs/config.link';
import { setAmplifyForLocalHost } from '../../../core/configs/config.app';
import ContextApp from 'vl-common/src/context/context.app';
import { LoadingButton } from 'vl-common/src/components/LoadingButton';
import AccountLayout from './Components/AccountLayout';
import PrivacyPolicyModal from './Components/PrivacyPolicyModal';
import TermsAndConditionsModal from './Components/TermsAndConditionsModal';
import { cognitoPasswordRE } from '@lib/cognitoPasswordRE';
import CpBaseLoading from '../../components/base/cp.base.loading';
import { useRuntimeConfig } from 'vl-common/src/hooks/Runtime';

function getSearchParams() {
  const [, qs] = window.location.href.split('?');

  return new URLSearchParams('?' + qs);
}

export function hasRedirectedFromSSO() {
  const qs = getSearchParams();

  return qs.has('state') && qs.has('code');
}

function hasSsoSet() {
  const qs = getSearchParams();

  return qs.has('sso');
}

function resetFilteringKeys() {
  const keysToDrop: string[] = [];

  for (let i = 0; i < window.sessionStorage.length; i++) {
    const key = window.sessionStorage.key(i);

    if (key?.startsWith('{"tableFilter":') || key?.startsWith('{"activeTabForPage":')) {
      keysToDrop.push(key);
    }
  }

  keysToDrop.forEach((key) => {
    console.log('Removing filtering key:', key);
    window.sessionStorage.removeItem(key);
  });
}
function Login({ history, location }) {
  const ctx = useContext(ContextApp);

  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState('login');
  const [userAPIObject, setUserAPIObject] = useState({});
  const [error, setError] = useState('');
  const [loginShowPassword, setLoginShowPassword] = useState(false);
  const params = new URLSearchParams(location.search);
  const from = params.get('from');
  const [loading, setLoading] = useState({
    login: false,
    registerNewPassword: false,
    registerSenderType: false,
    registerSendCode: false
  });
  const [serverSettings, setServerSettings] = useState({});
  const config = useRuntimeConfig();

  const {
    register: registerLogin,
    handleSubmit: handleSubmitLogin,
    // watch: watchLogin,
    errors: errorsLogin,
    reset: resetLogin,
    getValues: getValuesLogin
  } = useForm();
  const {
    register: registerNewPassword,
    handleSubmit: handleSubmitNewPassword,
    watch: watchNewPassword,
    errors: errorsNewPassword,
    reset: resetNewPassword
  } = useForm();
  const {
    register: registerSenderType,
    handleSubmit: handleSubmitSenderType,
    // watch: watchNewSenderType,
    errors: errorsSenderType
  } = useForm();
  const {
    register: registerSendCode,
    handleSubmit: handleSubmitSendCode,
    // watch: watchNewSendCode,
    errors: errorsSendCode
  } = useForm();

  const onSubmitLogin = async (data) => {
    window.restApiTimer();
    const { email, password } = data;
    await login(email, password);
  };

  const login = async (email, password) => {
    setError('');

    setLoading({
      ...loading,
      login: true
    });
    try {
      const user = await Auth.signIn(email, password);

      setUserAPIObject(user);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setCollapseOpen('newPassword');
        return;
      }
      if (user.challengeName === 'CUSTOM_CHALLENGE') {
        setCollapseOpen('senderType');
        return;
      }

      await loginSuccess();
    } catch (err) {
      if (err.message === 'Password reset required for the user') {
        history.push({
          pathname: ConfigLink.ResetYourPassword,
          search: '',
          state: { email }
        });
        return;
      }
      setError(err.message);
    }
    setLoading({
      ...loading,
      login: false
    });
  };

  const onSubmitNewPassword = async (data) => {
    try {
      setError('');
      setLoading({
        ...loading,
        registerNewPassword: true
      });

      const password = data.newPassword;

      // var user = await Auth.completeNewPassword(userAPIObject, password, {
      //   "name": 'empty'
      // });
      await LocalApi.user.newPassword({
        userobj: userAPIObject,
        password
      });

      let loginData = getValuesLogin();

      await login(loginData.email, password);
    } catch (err) {
      setError(err.response.data.errorMessage);
    }
    setLoading({
      ...loading,
      registerNewPassword: false
    });
  };

  const onSubmitSenderType = async (data) => {
    setError('');
    setLoading({
      ...loading,
      registerSenderType: true
    });
    const { onSubmitSenderType } = data;

    if (onSubmitSenderType === 'Email') {
      console.debug('select email type');
    }

    setCollapseOpen('sendCode');
    setLoading({
      ...loading,
      registerSenderType: false
    });
  };

  const onSubmitSendCode = async (data) => {
    setError('');
    setLoading({
      ...loading,
      registerSendCode: true
    });
    try {
      const { code } = data;
      let user = await Auth.sendCustomChallengeAnswer(userAPIObject, code);
      // user.username = "test"
      setUserAPIObject(user);

      await loginSuccess();
    } catch (err) {
      setError(err.message);
    }
    setLoading({
      ...loading,
      registerSendCode: false
    });
  };

  const loginSuccess = async () => {
    const page = from !== ConfigLink.AccountLogin ? from : null;

    resetLogin({});
    resetNewPassword({});

    const { data: user } = await LocalApi.user.getCurrentUser();
    const { user_type_code, user_role_code, login_url } = user;

    let url = sessionStorage.getItem('sso-redirect');

    sessionStorage.removeItem('sso-redirect');
    sessionStorage.removeItem('story-path');

    if (!url && !Auth.authenticatedUser) {
      switch (user_type_code) {
        case 'REFERRER':
          url = page || ConfigLink.ReferrerDashboard;
          break;
        case 'ADMIN':
          url = page || ConfigLink.ReferrerAdminDashboard;
          break;
        case 'CLIENTADMIN':
          url = user_role_code === 'REPORTING' ? ConfigLink.Reports : page || ConfigLink.Dashboard;
          break;
        default:
          await Auth.signOut({ global: true });
          window.location.replace(login_url);
          return;
      }
    }

    ctx.setAuth({ ...ctx.auth, authentication: false, reload: true });
    resetFilteringKeys();

    if (Auth.authenticatedUser) return;

    history.push(url);
  };

  useEffect(() => {
    const getSettings = async () => {
      if (config && 'IDENTITY_PROVIDERS' in config) {
        setAmplifyForLocalHost(config);
      }
      setServerSettings(config);
      try {
        await Auth.currentSession(); // This checks if the token has expired (doesn't contact server)
        await LocalApi.user.getCurrentUser(); // This checks if the token is valid (contacts server)
        console.warn('Already logged in');
        await loginSuccess();
      } catch (error) {
        console.warn('Not logged in');
        resetLogin({});
        resetNewPassword({});
      }
    };

    getSettings().catch(console.error);

    return function unMount() {};
  }, [config]);

  useEffect(() => {
    ctx.auth.isAuthenticated = false;
  }, []);

  if (sessionStorage.getItem('sso-redirect') || hasSsoSet() || hasRedirectedFromSSO()) {
    console.log('here ...', sessionStorage.getItem('sso-redirect'));
    return <CpBaseLoading />;
  }

  return (
    <AccountLayout>
      <Row>
        <Col className="text-left mx-auto px-md-4 vl-reset-your-pass-page" sm="12" md={{ size: 5 }}>
          <p className="mt-5 account-page-label">
            {collapseOpen === 'newPassword' ? 'Set New Password for ' : 'Log in to '}
            <span>Virtual Lucy</span>
          </p>
          {collapseOpen === 'newPassword' && (
            <div style={{ fontSize: '12px' }}>
              <p>Please note, your new password cannot be the same as your old one, and must have:</p>
              <p>
                <ul>
                  <li>
                    <strong>Minimum Length</strong>, which must be 8 characters but fewer than 99
                  </li>
                  <li>
                    <strong>Require Numbers</strong>, at least one number
                  </li>
                  <li>
                    <strong>Require Uppercase letters</strong>, at least one uppercase letter
                  </li>
                  <li>
                    <strong>Require Lowercase letters</strong>, at least one lowercase letter
                  </li>
                  <li>
                    <strong>Require a Special Character</strong>, from this set: ^ $ * . [ ] {} ( ) ? &quot; ! @ # %
                    &amp; / \ , &lt; &gt; &#39; : ; | _ ~ ` + &#39;`{' '}
                    <em>
                      Note: the plus &quot;+&quot; and minus &quot;-&quot; signs do not meet special character
                      requirements
                    </em>
                  </li>
                </ul>
              </p>
            </div>
          )}

          <p className="mt-2">
            <UncontrolledAlert className="account-login-alert" color="danger" isOpen={error !== ''}>
              <TiWarningOutline size="1.5em" /> {error}
            </UncontrolledAlert>
          </p>

          <Collapse isOpen={collapseOpen === 'login'}>
            <Form onSubmit={handleSubmitLogin(onSubmitLogin)} className="mt-5 text-left validation" autoComplete="off">
              <input type="hidden" value="something" />

              <FormGroup>
                <Label className="account-text" for="email">
                  Your email address<span className="vl-orange">*</span>
                </Label>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  autoComplete="off"
                  placeholder="email@site.com"
                  innerRef={registerLogin({
                    required: {
                      value: true,
                      message: 'This field is required'
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'invalid email address'
                    }
                  })}
                />
                {errorsLogin.email && <p>{errorsLogin.email.message}</p>}
              </FormGroup>
              <FormGroup>
                <Label className="account-text" for="password">
                  Password<span className="vl-orange">*</span>
                </Label>
                <InputGroup>
                  <Input
                    type={loginShowPassword ? 'text' : 'password'}
                    name="password"
                    id="password"
                    autoComplete="off"
                    placeholder="Enter your password"
                    innerRef={registerLogin({
                      required: {
                        value: true,
                        message: 'This field is required'
                      }
                    })}
                  />
                  <InputGroupText className="">
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        setLoginShowPassword(!loginShowPassword);
                      }}
                    >
                      <FiEye className={loginShowPassword ? 'd-none' : ''} />
                      <FiEyeOff className={loginShowPassword ? '' : 'd-none'} />
                    </a>
                  </InputGroupText>
                </InputGroup>
                {errorsLogin.password && <p>{errorsLogin.password.message}</p>}
              </FormGroup>
              <span className="vl-fs-medium">
                Forgot your password?{' '}
                <strong>
                  <Link to={ConfigLink.ForgetPassword}>Reset it?</Link>
                </strong>
              </span>

              <LoadingButton
                className="mt-5 py-2"
                block
                color="primary"
                type="submit"
                loading={loading.login}
                disabled={loading.login} //|| !isFormValid()
              >
                {loading.login ? 'Submitting...' : 'Submit'}
              </LoadingButton>

              <hr className="hr-login" />
            </Form>

            <div className="vstack gap-2">
              {serverSettings?.IDENTITY_PROVIDERS?.providers.map((provider, index) => (
                <Button
                  key={index}
                  block
                  onClick={() => {
                    sessionStorage.setItem('story-path', window.location.pathname);
                    Auth.federatedSignIn({
                      provider: provider.provider,
                      customState: btoa(
                        JSON.stringify({
                          storyPath: window.location.pathname
                        })
                      )
                    }).then();
                  }}
                >
                  {provider.label}
                </Button>
              ))}
            </div>
          </Collapse>

          <Collapse isOpen={collapseOpen === 'newPassword'}>
            <Form onSubmit={handleSubmitNewPassword(onSubmitNewPassword)} className="mt-5 text-left validation">
              <FormGroup>
                <Label className="account-text" for="newPassword">
                  Password<span className="vl-orange">*</span>
                </Label>
                <InputGroup>
                  <Input
                    type={loginShowPassword ? 'text' : 'password'}
                    name="newPassword"
                    id="newPassword"
                    placeholder="Enter your password"
                    autoComplete="off"
                    innerRef={registerNewPassword({
                      required: {
                        value: true,
                        message: 'This field is required'
                      },
                      pattern: {
                        value: cognitoPasswordRE,
                        message:
                          'minimum 8 characters and contain at least one digit, lowercase letter, uppercase letter, special character'
                      }
                    })}
                  />
                  <InputGroupText className="">
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        setLoginShowPassword(!loginShowPassword);
                      }}
                    >
                      <FiEye className={loginShowPassword ? 'd-none' : ''} />
                      <FiEyeOff className={loginShowPassword ? '' : 'd-none'} />
                    </a>
                  </InputGroupText>
                </InputGroup>
                {errorsNewPassword.newPassword && <p>{errorsNewPassword.newPassword.message}</p>}
              </FormGroup>
              <FormGroup>
                <Label className="account-text" for="confirmPassword">
                  Confirm Password<span className="vl-orange">*</span>
                </Label>
                <InputGroup>
                  <Input
                    type={loginShowPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="Enter your password"
                    autoComplete="off"
                    innerRef={registerNewPassword({
                      required: {
                        value: true,
                        message: 'This field is required'
                      },
                      validate: (value) => value === watchNewPassword('newPassword') || 'Passwords do not match'
                    })}
                  />
                  <InputGroupText className="">
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        setLoginShowPassword(!loginShowPassword);
                      }}
                    >
                      <FiEye className={loginShowPassword ? 'd-none' : ''} />
                      <FiEyeOff className={loginShowPassword ? '' : 'd-none'} />
                    </a>
                  </InputGroupText>
                </InputGroup>
                {errorsNewPassword.confirmPassword && <p>{errorsNewPassword.confirmPassword.message}</p>}
              </FormGroup>
              <LoadingButton
                className="mt-5 py-2"
                type="submit"
                block
                color="primary"
                loading={loading.registerNewPassword}
                disabled={loading.registerNewPassword} //|| !isFormValid()
              >
                {loading.registerNewPassword ? 'Submitting...' : 'Submit'}
              </LoadingButton>
              <hr className="hr-login" />
            </Form>
          </Collapse>
          <Collapse isOpen={collapseOpen === 'senderType'}>
            <Form onSubmit={handleSubmitSenderType(onSubmitSenderType)} className="mt-5 text-left validation">
              <FormGroup>
                <Label className="account-text" for="senderTypeValue">
                  Send Type Code<span className="vl-orange">*</span>
                </Label>
                <Input
                  type="select"
                  name="senderTypeValue"
                  autoComplete="off"
                  id="senderTypeValue"
                  innerRef={registerSenderType({
                    required: {
                      value: true,
                      message: 'This field is required'
                    }
                  })}
                >
                  <option value="EMAIL">Email</option>
                </Input>
                {errorsSenderType.senderTypeValue && <p>{errorsSenderType.senderTypeValue.message}</p>}
              </FormGroup>
              <LoadingButton
                className="mt-5 py-2 btn-block vl-bg-orange"
                type="submit"
                loading={loading.registerSenderType}
                disabled={loading.registerSenderType} //|| !isFormValid()
              >
                {loading.registerSenderType ? 'Submitting...' : 'Submit'}
              </LoadingButton>
              <hr className="hr-login" />
            </Form>
          </Collapse>
          <Collapse isOpen={collapseOpen === 'sendCode'}>
            <Form onSubmit={handleSubmitSendCode(onSubmitSendCode)} className="mt-5 text-left validation">
              <FormGroup>
                <Label className="account-text" for="code">
                  Please Enter Code:<span className="vl-orange">*</span>
                </Label>
                <Input
                  type="text"
                  name="code"
                  id="code"
                  autoComplete="off"
                  placeholder="123456"
                  innerRef={registerSendCode({
                    required: {
                      value: true,
                      message: 'This field is required'
                    }
                  })}
                />
                {errorsSendCode.code && <p>{errorsSendCode.code.message}</p>}
              </FormGroup>
              <LoadingButton
                className="mt-5 py-2 btn-block vl-bg-orange"
                type="submit"
                loading={loading.registerSendCode}
                disabled={loading.registerSendCode} //|| !isFormValid()
              >
                {loading.registerSendCode ? 'Submitting...' : 'Submit'}
              </LoadingButton>
              <hr className="hr-login" />
            </Form>
          </Collapse>
          <div className="mt-5 text-center vl-fs-medium">
            <br />
            <br />
          </div>
        </Col>
      </Row>
      <TermsAndConditionsModal
        hideAcceptButton={true}
        close={() => {
          setShowTermsAndConditionsModal(false);
        }}
        show={showTermsAndConditionsModal}
      />
      <PrivacyPolicyModal
        hideAcceptButton={true}
        close={() => {
          setShowPrivacyPolicyModal(false);
        }}
        show={showPrivacyPolicyModal}
      />
    </AccountLayout>
  );
}

export default withRouter(Login);
